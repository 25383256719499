.subscriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 620px) {
  .subscriptionContainer {
    width: 380px;
    margin: 0 auto;
  }
}

@media (max-width: 390px) {
  .subscriptionContainer {
    width: 350px;
  }
}

@media (max-width: 350px) {
  .subscriptionContainer {
    width: 300px;
  }
}

.subscriptionImgContainer {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.imgSubscription {
  width: 50px;
  height: 50px;
  background-image: url("../../../public/images/subscription.svg");
  background-repeat: no-repeat;
}

@media (max-width: 620px) {
  .imgSubscription {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 390px) {
  .imgSubscription {
    width: 30px;
    height: 30px;
  }
}

.headerText {
  margin: 0;
  font-family: "Nunito Black 900";
  font-size: 30px;
}

@media (max-width: 620px) {
  .headerText {
    font-size: 25px;
  }
}

@media (max-width: 390px) {
  .headerText {
    font-size: 21px;
  }
}

.text {
  margin: 0;
  margin-top: 15px;
  font-family: "Nunito Regular";
  font-size: 20px;
}

@media (max-width: 620px) {
  .text {
    font-size: 15px;
    margin-top: 15px;
    text-align: center;
  }
}

@media (max-width: 390px) {
  .text {
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
  }
}

.link {
  font-family: "Nunito Regular";
  margin-top: 15px;
  font-size: 19px;
  color: black;
}

.historyText {
  margin: 0;
  font-family: "Nunito Black 900";
  font-size: 30px;
}

@media (max-width: 620px) {
  .historyText {
    font-size: 25px;
  }
}

@media (max-width: 390px) {
  .historyText {
    font-size: 21px;
  }
}

.imgSubscriptionTwo {
  width: 50px;
  height: 50px;
  background-image: url("../../../public/images/subscription.svg");
  background-repeat: no-repeat;
}

@media (max-width: 620px) {
  .imgSubscriptionTwo {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 620px) {
  .imgSubscriptionTwo {
    width: 30px;
    height: 30px;
  }
}

.tableContainer {
  /* width: 650px; */
  margin-top: 15px;
}

/* @media (max-width: 620px) {
  .tableContainer {
    width: 380px;
  }
} */
