.header {
  margin-top: 30px;
  background-color: #038dbe;
  width: 200px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

@media (max-width: 2000px) {
  .header {
    height: 120vh;
    width: 400px;
  }
}
/* 
@media (max-width: 1000px) {
  .header {
    min-height: 50px;
  }
}

@media (max-width: 660px) {
  .header {
    display: flex;
    justify-content: space-between;
    min-height: 102px;
  }
}

@media (max-width: 620px) {
  .header {
    min-height: 66px;
    width: 380px;
    align-self: center;
  }
}

@media (max-width: 390px) {
  .header {
    min-height: 58px;
    width: 350px;
    align-self: center;
  }
}

@media (max-width: 350px) {
  .header {
    min-height: 44px;
  }
} */

.links {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin-left: 20px;
  gap: 30px;
}

@media (max-width: 1040px) {
  .links {
    margin-left: 20px;
    margin-right: 21.68px;
  }
}

@media (max-width: 780px) {
  .links {
    margin-left: 29px;
    margin-right: 21.68px;
    column-gap: 14px;
  }
}

/* @media (max-width: 660px) {
      .links {
        margin-left: 30px;
        margin-right: 15px;
        column-gap: 10px;
      }
    } */

@media (max-width: 660px) {
  .links {
    display: none;
  }
}

.links a {
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  line-height: 22.5px;
  position: relative;
  color: #ffffff;
  text-decoration: none;
  padding-bottom: 5px;
  transition: color 0.9s;
}

@media (max-width: 1040px) {
  .links a {
    font-size: 11px;
    line-height: 17.53px;
  }
}

@media (max-width: 780px) {
  .links a {
    font-size: 9px;
    line-height: 20px;
  }
}

.links a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.5px;
  background-color: #fab325;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.5s;
}

.links a:hover::before,
.links a.activeLink::before {
  transform: scaleX(1);
}

.hamburger {
  display: none;
  margin-right: 15px;
  flex-direction: column;
  justify-content: space-around;
  width: 40px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
  padding: 5px 0;
}

.hamburger span {
  display: flex;
  width: 100%;
  height: 4px;
  margin-bottom: 10px;
  background: white;
  transition: all 0.3s linear;
}

@media (max-width: 660px) {
  .hamburger span {
    display: flex;
  }
}

@media (max-width: 660px) {
  .hamburger {
    display: flex;
  }
}

@media (max-width: 620px) {
  .hamburger {
    display: flex;
  }
}

@media (max-width: 390px) {
  .hamburger {
    width: 30px;
    padding: 4px 0;
    margin-right: 12px;
  }
  .hamburger span {
    height: 3px;
    margin-bottom: 7px;
  }
}

.quantityGamesText {
  font-size: 13px;
  font-weight: 300;
  font-style: normal;
  line-height: 22.5px;
  position: relative;
  color: #ffffff;
  text-decoration: none;
  padding-bottom: 5px;
  transition: color 0.9s;
}

@media (max-width: 1040px) {
  .quantityGamesText {
    font-size: 11px;
  }
}

.dropdown {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  list-style: none;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  line-height: 22.5px;
  color: #ffffff;
}

@media (max-width: 1040px) {
  .dropdown {
    padding: 0;
    font-size: 10px;
    padding-left: 25px;
  }
}

.subcategoryGames {
  cursor: pointer;
}
