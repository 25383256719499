.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  font-family: "Nunito Bold";
  font-size: 20px;
}

@media (max-width: 620px) {
  .header {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.text {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  font-family: "Nunito Regular";
  font-size: 15px;
  line-height: 20px;
}

.btnLogin {
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: #5fbf77;
  color: #ffffff;
  border-radius: 13.5px;
  border: 1px;
  width: 107px;
  height: 37px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  background-repeat: no-repeat;
  background-position: 80%;
  cursor: pointer;
  padding-right: 1.6%;
  transition: background-color 0.4s;
}
