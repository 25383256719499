.profileContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 620px) {
  .profileContainer {
    width: 380px;
    margin: 0 auto;
  }
}

@media (max-width: 390px) {
  .profileContainer {
    width: 350px;
  }
}

@media (max-width: 350px) {
  .profileContainer {
    width: 320px;
  }
}

@media (max-width: 330px) {
  .profileContainer {
    width: 300px;
  }
}

.headerText {
  margin: 0;
  margin-top: 100px;
  font-family: "Nunito Black 900";
  font-size: 30px;
}

@media (max-width: 620px) {
  .headerText {
    font-size: 25px;
    margin-top: 50px;
  }
}

@media (max-width: 390px) {
  .headerText {
    font-size: 21px;
    margin-top: 30px;
  }
}

.text {
  margin: 0;
  margin-top: 15px;
  font-family: "Nunito Regular";
  font-size: 20px;
}

@media (max-width: 620px) {
  .text {
    font-size: 15px;
    margin-top: 10px;
    text-align: center;
  }
}

@media (max-width: 390px) {
  .text {
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
  }
}

.loginPasswordContainer {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
}

@media (max-width: 620px) {
  .loginPasswordContainer {
    width: 380px;
    margin: 0 auto;
  }
}

@media (max-width: 390px) {
  .loginPasswordContainer {
    width: 350px;
  }
}

@media (max-width: 350px) {
  .loginPasswordContainer {
    width: 320px;
  }
}

@media (max-width: 330px) {
  .loginPasswordContainer {
    width: 300px;
  }
}

.btnRegistration {
  margin-top: 5px;
  background-color: #5fbf77;
  color: #ffffff;
  border-radius: 13.5px;
  border: 1px;
  width: 210px;
  height: 50px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  background-repeat: no-repeat;
  background-position: 80%;
  cursor: pointer;
  padding-right: 1.6%;
  transition: background-color 0.4s;
}

@media (max-width: 390px) {
  .btnRegistration {
    width: 350px;
    height: 50px;
    border-radius: 0px;
    font-size: 11px;
  }
}

@media (max-width: 350px) {
  .btnRegistration {
    width: 320px;
  }
}

@media (max-width: 330px) {
  .btnRegistration {
    width: 300px;
  }
}

.btnRegistration:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  border: 1px solid #aaaaaa; /* Опционально: изменение границы при disabled */
}

.textError {
  text-align: center;
  font-family: "Nunito Regular";
  color: red;
  font-size: 16px;
}

.textOptions {
  font-family: "Nunito Regular";
  font-size: 12px;
}
