@import "../normalize.css";
@import "../fonts/fonts.css";

/* :root {
  --max-width: 1000px;
  --border-radius: 12px;
} */

html,
body {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
  margin: 0;
  font-family: "Inter", sans-serif;
}

.container {
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
