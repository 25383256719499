.header {
  background-color: #038dbe;
  min-height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 2000px) {
  .header {
    height: 50px;
  }
}

@media (max-width: 1000px) {
  .header {
    min-height: 50px;
  }
}

@media (max-width: 660px) {
  .header {
    display: flex;
    justify-content: space-between;
    min-height: 102px;
  }
}

@media (max-width: 620px) {
  .header {
    margin: 0 auto;
    min-height: 66px;
    width: 380px;
  }
}

@media (max-width: 390px) {
  .header {
    min-height: 58px;
    width: 350px;
  }
}

@media (max-width: 350px) {
  .header {
    min-height: 44px;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 660px) {
  .logoContainer {
    margin-left: 12px;
  }
}

@media (max-width: 620px) {
  .logoContainer {
    margin: 0;
  }
}

@media (max-width: 350px) {
  .logoContainer {
    margin-left: 13.23px;
  }
}

@media (max-width: 3000px) {
  .logo {
    width: 65px;
    height: 80px;
    margin-right: 20px;
  }
}

@media (max-width: 1000px) {
  .logo {
    width: 52px;
    height: 49px;
  }
}

@media (max-width: 780px) {
  .logo {
    margin-right: 10px;
    width: 52px;
    height: 49px;
  }
}

@media (max-width: 660px) {
  .logo {
    width: 106px;
    height: 105px;
  }
}

@media (max-width: 620px) {
  .logo {
    width: 65px;
    height: 61px;
  }
}

@media (max-width: 390px) {
  .logo {
    width: 57px;
    height: 53.42px;
  }
}

@media (max-width: 350px) {
  .logo {
    width: 48px;
    height: 43px;
  }
}

.companyName {
  font-family: "Ubuntu";
  font-size: 19px;
  line-height: 14px;
  font-weight: 400;
  color: #ffffff;
}

@media (max-width: 1000px) {
  .companyName {
    margin-left: 5px;
    font-size: 13.43px;
    line-height: 10.91px;
  }
}

@media (max-width: 780px) {
  .companyName {
    margin: 0;
    margin-top: 1px;
    font-size: 11.49px;
    line-height: 9.34px;
  }
}

@media (max-width: 660px) {
  .companyName {
    margin: 0;
    margin-left: 10px;
    font-size: 52px;
    line-height: 21.83px;
  }
}

@media (max-width: 620px) {
  .companyName {
    margin: 0;
    margin-left: 10px;
    font-size: 32px;
    line-height: 13px;
  }
}

@media (max-width: 350px) {
  .companyName {
    margin: 0;
    margin-left: 4.67px;
    font-size: 26px;
    line-height: 10.91px;
  }
}

.links {
  display: flex;
  list-style-type: none;
  margin-left: 66px;
  margin-right: 22px;
  padding: 0;
  column-gap: 70px;
}

@media (max-width: 1000px) {
  .links {
    margin-left: 39px;
    margin-right: 21.68px;
    column-gap: 16.36px;
  }
}

@media (max-width: 780px) {
  .links {
    margin-left: 29px;
    margin-right: 21.68px;
    column-gap: 14px;
  }
}

/* @media (max-width: 660px) {
    .links {
      margin-left: 30px;
      margin-right: 15px;
      column-gap: 10px;
    }
  } */

@media (max-width: 660px) {
  .links {
    display: none;
  }
}

.links a {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  line-height: 22.5px;
  position: relative;
  color: #ffffff;
  text-decoration: none;
  padding-bottom: 5px;
  transition: color 0.9s;
}

@media (max-width: 1000px) {
  .links a {
    font-size: 11px;
    line-height: 17.53px;
  }
}

@media (max-width: 780px) {
  .links a {
    font-size: 9px;
    line-height: 20px;
  }
}

.links a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.5px;
  background-color: #fab325;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.5s;
}

.links a:hover::before,
.links a.activeLink::before {
  transform: scaleX(1);
}

.btnDemo {
  background-color: #5fbf77;
  color: #ffffff;
  border-radius: 13.5px;
  border: 1px;
  width: 211.5px;
  height: 37px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  cursor: pointer;
  transition: background-color 0.4s;
}

@media (max-width: 1000px) {
  .btnDemo {
    border-radius: 10.52px;
    border: 1px;
    width: 168.56px;
    height: 28.83px;
    font-size: 12px;
    line-height: 14.8px;
  }
}

@media (max-width: 780px) {
  .btnDemo {
    border-radius: 9px;
    border: 1px;
    width: 141px;
    height: 24.67px;
    font-size: 10px;
    line-height: 12.67px;
  }
}

@media (max-width: 660px) {
  .btnDemo {
    display: none;
  }
}

.btnLogin {
  margin-left: 11.36px;
  background-color: #5fbf77;
  color: #ffffff;
  border-radius: 13.5px;
  border: 1px;
  width: 107px;
  height: 37px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  background-repeat: no-repeat;
  background-position: 80%;
  cursor: pointer;
  transition: background-color 0.4s;
}

@media (max-width: 1000px) {
  .btnLogin {
    margin-left: 13px;
    background-position: 75%;
    border-radius: 10.52px;
    width: 85.09px;
    height: 29.02px;
    font-size: 12px;
    line-height: 14.8px;
    background-size: 11.69px 11.1px;
  }
}

@media (max-width: 780px) {
  .btnLogin {
    margin-left: 10px;
    background-position: 75%;
    border-radius: 9px;
    width: 73px;
    height: 25px;
    font-size: 10.27px;
    line-height: 12.67px;
    background-size: 10px 30px;
  }
}

@media (max-width: 660px) {
  .btnLogin {
    display: none;
  }
}

.btnDemo:hover {
  background-color: #fab325;
  color: black;
}

.btnLogin:hover {
  background-color: #fab325;
  color: black;
}

.hamburger {
  display: none;
  margin-right: 15px;
  flex-direction: column;
  justify-content: space-around;
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
  padding: 5px 0;
  position: relative;
}

.hamburger span {
  display: block;
  width: 100%;
  height: 4px;
  background: white;
  transition: all 0.3s linear;
  position: absolute;
  left: 0;
}

.hamburger span:first-child {
  top: 10%;
}

.hamburger span:nth-child(2) {
  top: 45%;
}

.hamburger span:nth-child(3) {
  top: 80%;
}

/* Стили для крестика */
.hamburger.cross span:first-child {
  transform: rotate(45deg);
  top: 45%; /* Центрировать относительно кнопки */
}

.hamburger.cross span:nth-child(2) {
  opacity: 0; /* Скрываем вторую полоску */
}

.hamburger.cross span:nth-child(3) {
  transform: rotate(-45deg);
  top: 45%; /* Центрировать относительно кнопки */
}
.hamburger.disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 660px) {
  .hamburger span {
    display: flex;
  }
}

@media (max-width: 660px) {
  .hamburger {
    display: flex;
  }
}

@media (max-width: 620px) {
  .hamburger {
    display: flex;
  }
}

@media (max-width: 390px) {
  .hamburger {
    width: 30px;
    padding: 4px 0;
    margin-right: 15px;
  }
  .hamburger span {
    height: 3px;
    margin-bottom: 7px;
  }
}

@media (max-width: 338px) {
  .hamburger {
    margin-right: 29px;
  }
}

@media (max-width: 325px) {
  .hamburger {
    margin-right: 38px;
  }
}

.mobileMenu {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  gap: 30px;
  top: 12%; /* Позиционирование ниже гамбургера */
  left: 0;
  /* background-color: #3b18ab; Цвет фона меню */
  background-color: #038dbe;
  width: 100%;
  height: 88vh;
  z-index: 15;
  list-style-type: none;
  overflow-y: hidden;
}

@media (max-width: 620px) {
  .mobileMenu {
    top: 66px;
    width: 380px;
    left: 50%;
    transform: translateX(-50%);
    height: calc(100vh - 66px);
  }
}

@media (max-width: 390px) {
  .mobileMenu {
    top: 58px;
    width: 350px;
    transform: translateX(-50%);
    height: calc(100vh - 58px);
  }
}

@media (max-width: 350px) {
  .mobileMenu {
    top: 50px;
    width: 100%;
    transform: translateX(-50%);
    height: calc(100vh - 50px);
  }
}

.mobileMenu a {
  font-size: 25px;
  font-weight: 300;
  font-style: normal;
  line-height: 22.5px;
  position: relative;
  color: #ffffff;
  text-decoration: none;
  padding-bottom: 5px;
  transition: color 0.9s;
}

/* .mobileMenu li:first-child {
  margin-top: 20px; 
} */

.btnLoginMobile {
  background-color: #5fbf77;
  color: #ffffff;
  border-radius: 13.5px;
  border: 1px;
  width: 107px;
  height: 37px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  background-repeat: no-repeat;
  background-position: 80%;
  cursor: pointer;
  padding-right: 1.6%;
  transition: background-color 0.4s;
}

.btnLoginMobile:hover {
  background-color: #fab325;
  color: black;
}

.unavaliable {
  pointer-events: none;
  cursor: not-allowed;
  color: gray;
  text-decoration: none;
}
