/* .test {
  display: flex;
  flex-direction: row;
  background-color: #038dbe;
} */

.centerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.container {
  margin: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 25px;
  margin-right: 330px;
  grid-gap: 55px;
}

@media (max-width: 1600px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
  }
}

@media (max-width: 1400px) {
  .container {
    margin-right: 150px;
  }
}

@media (max-width: 1155px) {
  .container {
    margin-right: 50px;
  }
}

@media (max-width: 1000px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
    margin-right: 200px;
    grid-gap: 15px;
  }
}

@media (max-width: 802px) {
  .container {
    margin-right: 140px;
  }
}

.gameContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* new */
  align-items: center;
  height: 350px; /* new */
}

.imageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image {
  width: 200px;
  height: 200px;
}

@media (max-width: 1000px) {
  .image {
    width: 120px;
    height: 120px;
  }
}

.gameHeader {
  margin: 0;
  margin-top: 10px;
  max-width: 200px;
  text-align: center;
  font-family: "Nunito Bold";
  font-size: 16px;
  color: white;
  min-height: 30px; /* new */
}

.gameDescription {
  margin: 0;
  margin-top: 10px;
  font-family: "Nunito Regular";
  text-align: center;
  font-size: 13px;
  color: white;
  min-height: 40px; /* new */
}

.btn {
  margin-top: auto; /* new */
  background-color: #5fbf77;
  color: #ffffff;
  border-radius: 13.5px;
  border: 1px;
  width: 107px;
  height: 37px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  background-repeat: no-repeat;
  background-position: 80%;
  cursor: pointer;
  transition: background-color 0.4s;
}

@media (max-width: 1000px) {
  .btn {
    margin-left: 13px;
    background-position: 75%;
    border-radius: 10.52px;
    width: 85.09px;
    height: 29.02px;
    font-size: 12px;
    line-height: 14.8px;
    background-size: 11.69px 11.1px;
  }
}

@media (max-width: 780px) {
  .btn {
    margin-left: 10px;
    background-position: 75%;
    border-radius: 9px;
    width: 73px;
    height: 25px;
    font-size: 10.27px;
    line-height: 12.67px;
    background-size: 10px 30px;
  }
}

@media (max-width: 660px) {
  .btn {
    display: none;
  }
}

.btn:hover {
  background-color: #fab325;
  color: black;
}
