@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Inter-Regular.woff2") format("woff2"),
    url("./Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: "Inter Light";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./Inter-Light.woff2") format("woff2"),
    url("./Inter-Light.woff") format("woff");
}

/* @font-face {
  font-family: "Nunito Black";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./Nunito-Black.woff2") format("woff2"),
    url("./Nunito-Black.woff") format("woff");
} */

@font-face {
  font-family: "Nunito Black 900";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./Nunito-Black111.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Bold";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./Nunito-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Regular";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Ubuntu-R.ttf") format("truetype");
}
