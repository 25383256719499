.sectionPricing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 50px;
}

@media (max-width: 1000px) {
  .sectionPricing {
    margin-top: 90px;
  }
}

@media (max-width: 780px) {
  .sectionPricing {
    margin-top: 48px;
  }
}

@media (max-width: 660px) {
  .sectionPricing {
    margin-top: 85px;
  }
}

@media (max-width: 620px) {
  .sectionPricing {
    margin-top: 49.37px;
  }
}

@media (max-width: 390px) {
  .sectionPricing {
    margin-top: 60px;
  }
}

/* @media (max-width: 350px) {
  .sectionPricing {
    width: 300px;
  }
} */

.pricingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 57px;
}

@media (max-width: 1000px) {
  .pricingContainer {
    gap: 46px;
  }
}

@media (max-width: 780px) {
  .pricingContainer {
    gap: 40px;
  }
}

@media (max-width: 660px) {
  .pricingContainer {
    margin: 0;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
}

@media (max-width: 1000px) {
  .headerContainer {
    margin-bottom: 31px;
  }
}

.headerPricing {
  margin: 0;
  font-family: "Nunito Black 900";
  font-size: 28px;
  line-height: 30.8px;
}

@media (max-width: 1000px) {
  .headerPricing {
    font-size: 22px;
    line-height: 24.2px;
  }
}

@media (max-width: 780px) {
  .headerPricing {
    font-size: 19px;
    line-height: 20.9px;
  }
}

@media (max-width: 660px) {
  .headerPricing {
    font-size: 40px;
    line-height: 44px;
  }
}

@media (max-width: 620px) {
  .headerPricing {
    width: 390px;
    text-align: center;
    font-size: 25px;
    line-height: 28px;
  }
}

@media (max-width: 390px) {
  .headerPricing {
    width: 350px;
    font-size: 21px;
    line-height: 23px;
  }
}

@media (max-width: 350px) {
  .headerPricing {
    font-size: 20px;
    line-height: 22px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  margin-top: 40px;
  width: 280px;
  height: 568px;
}

@media (max-width: 1000px) {
  .container {
    margin-top: 31px;
    width: 218px;
    height: 443px;
  }
}

@media (max-width: 780px) {
  .container {
    margin-top: 26px;
    width: 187px;
    height: 379px;
  }
}

@media (max-width: 660px) {
  .container {
    width: 400px;
    height: 550px;
  }
}

@media (max-width: 620px) {
  .container {
    width: 380px;
    height: 550px;
  }
}

@media (max-width: 390px) {
  .container {
    width: 350px;
    height: 450px;
  }
}

@media (max-width: 350px) {
  .container {
    width: 300px;
    height: 400px;
  }
}

.blue {
  border: 0.1px solid #038dbe;
}

.red {
  border: 0.1px solid #e8505b;
}

.green {
  border: 0.1px solid #5fbf77;
}

.subGames {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 281px;
  height: 83px;
  border-top-left-radius: 3px;
  border-top-right-radius: 5px;
  font-family: "Nunito Black 900";
  font-size: 18px;
  color: #ffffff;
}

@media (max-width: 1000px) {
  .subGames {
    width: 218px;
    height: 82px;
    font-size: 14px;
  }
}

@media (max-width: 780px) {
  .subGames {
    width: 187px;
    height: 55px;
    font-size: 12px;
  }
}

@media (max-width: 660px) {
  .subGames {
    width: 400px;
    height: 55px;
    font-size: 20px;
  }
}

@media (max-width: 620px) {
  .subGames {
    width: 380px;
    height: 55px;
    font-size: 17px;
  }
}

@media (max-width: 390px) {
  .subGames {
    width: 350px;
    height: 55px;
    /* font-size: 12px; */
  }
}

@media (max-width: 350px) {
  .subGames {
    width: 300px;
    height: 55px;
    font-size: 15px;
  }
}

.backgroundBlue {
  background-color: #038dbe;
}

.backgroundRed {
  text-align: center;
  background-color: #ff5d7d;
  height: 89px;
}

@media (max-width: 780px) {
  .backgroundRed {
    height: 59.5px;
  }
}

.backgroundGreen {
  background-color: #5fbf77;
}

.firtsElement {
  margin-top: 27px;
  margin-bottom: 5px;
  font-family: "Nunito Black 900";
  font-size: 18px;
  text-align: center;
}

@media (max-width: 1000px) {
  .firtsElement {
    margin-top: 21px;
    font-size: 14px;
  }
}

@media (max-width: 780px) {
  .firtsElement {
    margin-top: 18px;
    font-size: 12px;
  }
}

@media (max-width: 660px) {
  .firtsElement {
    font-size: 25px;
  }
}

@media (max-width: 390px) {
  .firtsElement {
    font-size: 25px;
  }
}

@media (max-width: 350px) {
  .firtsElement {
    font-size: 15px;
  }
}

.price {
  font-family: "Nunito Black 900";
  font-size: 48px;
  text-align: center;
}

@media (max-width: 1000px) {
  .price {
    font-size: 23px;
  }
}

@media (max-width: 780px) {
  .price {
    font-size: 32px;
  }
}

@media (max-width: 660px) {
  .price {
    font-size: 45px;
  }
}

@media (max-width: 350px) {
  .price {
    font-size: 25px;
  }
}

.bluePrice {
  color: #038dbe;
}

.redPrice {
  color: #e8505b;
  margin-bottom: 13px;
}

.greenPrice {
  color: #5fbf77;
  margin-bottom: 13px;
}

.secondElement {
  font-family: "Nunito Bold";
  font-size: 21px;
  text-align: center;
}

@media (max-width: 1000px) {
  .secondElement {
    font-size: 16.36px;
  }
}

@media (max-width: 780px) {
  .secondElement {
    font-size: 14px;
  }
}

@media (max-width: 660px) {
  .secondElement {
    font-size: 24px;
  }
}

@media (max-width: 350px) {
  .secondElement {
    font-size: 15px;
  }
}

.btnContainer {
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1000px) {
  .btnContainer {
    margin-top: 11.33px;
  }
}

@media (max-width: 780px) {
  .btnContainer {
    margin-top: 9px;
  }
}

@media (max-width: 660px) {
  .btnContainer {
    margin-top: 12px;
  }
}

.btn {
  width: 250px;
  height: 51px;
  border-radius: 13.5px;
  text-align: center;
  color: white;
  cursor: pointer;
  border: 1px;
  transition: background-color 0.4s;
}

@media (max-width: 1000px) {
  .btn {
    font-size: 12px;
    width: 156px;
    height: 40px;
  }
}

@media (max-width: 780px) {
  .btn {
    font-size: 10px;
    width: 134px;
    height: 33.9px;
  }
}

@media (max-width: 660px) {
  .btn {
    font-size: 15px;
    width: 250px;
    height: 40px;
  }
}

@media (max-width: 350px) {
  .btn {
    font-size: 12px;
    width: 250px;
    height: 40px;
  }
}

.btnBlue {
  background-color: #038dbe;
}

.btnRed {
  width: 250px;
  height: 51px;
  background-color: #e8505b;
  margin-top: 12px;
}

@media (max-width: 1000px) {
  .btnRed {
    font-size: 12px;
    width: 173px;
    height: 40px;
  }
}

@media (max-width: 780px) {
  .btnRed {
    margin-top: 6px;
    font-size: 10px;
    width: 148px;
    height: 33.9px;
  }
}

@media (max-width: 660px) {
  .btnRed {
    font-size: 15px;
    width: 250px;
    height: 40px;
  }
}

@media (max-width: 350px) {
  .btnRed {
    font-size: 12px;
    width: 250px;
    height: 40px;
  }
}

.btnGreen {
  background-color: #5fbf77;
  margin-top: 8px;
}

.btn:hover {
  background-color: #fab325;
  color: black;
}

.imageGirlPuzzle {
  background-image: url("../../../public/images/girlPuzzle.svg");
  margin-top: 57.2px;
  margin-left: 43.46px;
  width: 209px;
  height: 201px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1000px) {
  .imageGirlPuzzle {
    margin-top: 45px;
    margin-left: 34px;
    width: 163px;
    height: 157px;
  }
}

@media (max-width: 780px) {
  .imageGirlPuzzle {
    margin-top: 38px;
    margin-left: 29px;
    width: 139px;
    height: 134px;
  }
}

@media (max-width: 660px) {
  .imageGirlPuzzle {
    margin: 0;
    margin-top: 38px;
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 390px) {
  .imageGirlPuzzle {
    margin: 0;
    margin-top: 38px;
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 350px) {
  .imageGirlPuzzle {
    margin: 0;
    margin-top: 45px;
    width: 150px;
    height: 150px;
  }
}

.imageWomanBook {
  background-image: url("../../../public/images/woman-book.svg");
  margin-top: 57.2px;
  width: 190px;
  height: 258px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 20px;
  margin-left: 70.43px;
}

@media (max-width: 1000px) {
  .imageWomanBook {
    margin-top: 29.5px;
    margin-left: 63.64px;
    width: 137px;
    height: 185.5px;
  }
}

@media (max-width: 780px) {
  .imageWomanBook {
    margin-top: 14px;
    margin-left: 47px;
    width: 127px;
    height: 172px;
  }
}

@media (max-width: 660px) {
  .imageWomanBook {
    margin: 0;
    margin-top: 38px;
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 390px) {
  .imageWomanBook {
    margin: 0;
    margin-top: 38px;
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 350px) {
  .imageWomanBook {
    margin: 0;
    margin-top: 45px;
    width: 150px;
    height: 150px;
  }
}

.imageFamily {
  background-image: url("../../../public/images/family.svg");
  width: 218px;
  height: 163px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 90px;
}

@media (max-width: 1000px) {
  .imageFamily {
    width: 170px;
    height: 128px;
  }
}

@media (max-width: 780px) {
  .imageFamily {
    width: 145px;
    height: 109px;
  }
}

@media (max-width: 660px) {
  .imageFamily {
    margin: 0;
    margin-top: 20px;
    width: 250px;
    height: 190px;
  }
}

@media (max-width: 390px) {
  .imageFamily {
    margin: 0;
    margin-top: 20px;
    width: 150px;
    height: 200px;
  }
}

@media (max-width: 350px) {
  .imageFamily {
    margin: 0;
    margin-top: 20px;
    width: 150px;
    height: 150px;
  }
}
