@media (min-width: 1636px) {
  .container {
    max-width: 83%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.title {
  margin: 0;
  color: black;
  font-family: "Nunito Regular";
  font-size: 80px;
  text-align: center;
}

@media (max-width: 1000px) {
  .header {
    font-size: 60px;
  }
}

.image {
  width: 80%;
  display: block;
  margin: 0 auto;
}
