.modal {
  width: 450px;
  border-radius: 40px;
  background-color: #e3f3ef;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 620px) {
  .modal {
    width: 300px;
  }
}

.close {
  position: absolute;
  top: 40px;
  right: 40px;
}

.close__button {
  background-repeat: no-repeat;
  background-position: center;
  background-color: #1c1c21;
  border: 0;
}
