.centerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.searchBar {
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 800px;
  margin-top: 1%;
  margin-left: 2.3%;
  padding: 8px 12px;
  border: 2px solid rgba(3, 141, 190, 1);
  border-radius: 12px;
  background-color: #fff;
}

.searchIcon {
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg fill='none' stroke='%23777777' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.searchInput {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
}

.containerWrapper {
  position: relative;
  width: 100%;
}

.cubes {
  position: absolute;
  top: 1%;
  right: 0%;
  z-index: 2;
  width: 30%;
}

@media (max-width: 1651px) {
  .cubes {
    right: 2%;
  }
}

@media (max-width: 1572px) {
  .cubes {
    right: 1%;
  }
}

@media (max-width: 1479px) {
  .cubes {
    top: 0.5%;
    right: 4%;
  }
}

@media (max-width: 1356px) {
  .cubes {
    top: 0.5%;
    right: 2%;
  }
}

@media (max-width: 1279px) {
  .cubes {
    top: 0.5%;
    right: 5%;
  }
}

@media (max-width: 1202px) {
  .cubes {
    top: 0.5%;
    right: 0%;
  }
}

@media (max-width: 1183px) {
  .cubes {
    top: 0.3%;
    right: 9%;
  }
}

@media (max-width: 1097px) {
    .cubes {
      top: 0.3%;
      right: 6%;
    }
  }

  @media (max-width: 1040px) {
    .cubes {
      top: 0.3%;
      right: 9%;
    }
  }
  @media (max-width: 950px) {
    .cubes {
      top: 0.3%;
      right: 7%;
    }
  }

  @media (max-width: 889px) {
    .cubes {
      top: 0.3%;
      right: 3%;
    }
  }

  @media (max-width: 863px) {
    .cubes {
      top: 0.3%;
      right: 6%;
    }
  }


/* @media (max-width: 1628px) {
    .cubes {
      right: 0%;
    }
  } */

.blueSpot,
.greenSpot,
.orangeSpot {
  position: absolute;
  z-index: 0;
  width: 50%;
}

.blueSpot {
  top: 8%;
  left: 30%;
}

.greenSpot {
  top: 40%;
  left: 20%;
}

.orangeSpot {
  top: 70%;
  left: 45%;
}

.container {
  position: relative;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(4, minmax(225px, 1fr));
  margin-top: 10%;
  width: 100%;
  grid-gap: 2%;
  z-index: 1;
}

@media (max-width: 1651px) {
  .container {
    grid-template-columns: repeat(4, minmax(150px, 1fr));
  }
}

@media (max-width: 1479px) {
  .container {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
}

@media (max-width: 1183px) {
  .container {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }
}

.gameContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 357px;
  width: 258px;
  padding: 20px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 1.5px 6px 12.75px rgba(0, 0, 0, 0.05),
    -4px -4px 4px rgba(141, 76, 76, 0.05),
    4.5px 1.5px 11.25px rgba(0, 0, 0, 0.02);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@media (max-width: 1651px) {
  .gameContainer {
    width: 218px;
  }
}

@media (max-width: 1479px) {
  .gameContainer {
    width: 240px;
  }
}

@media (max-width: 1282px) {
  .gameContainer {
    width: 190px;
  }
}

@media (max-width: 1183px) {
  .gameContainer {
    width: 250px;
  }
}

@media (max-width: 863px) {
  .gameContainer {
    width: 200px;
  }
}

.gameContainer:hover {
  transform: translateY(-5px);
  box-shadow: 1.5px 6px 15px rgba(0, 0, 0, 0.1),
    -4px -4px 6px rgba(141, 76, 76, 0.1), 6px 2px 14px rgba(0, 0, 0, 0.04);
}

.imageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 100%;
}

.image {
  width: 200px;
  height: 200px;
}

/* @media (max-width: 1000px) {
  .image {
    width: 120px;
    height: 120px;
  }
} */

.gameHeader {
  margin: 0;
  margin-top: 10px;
  max-width: 200px;
  text-align: center;
  font-family: "Nunito Bold";
  font-size: 16px;
  color: black;
  min-height: 30px;
}

.gameDescription {
  margin: 0;
  margin-top: 10px;
  font-family: "Nunito Regular";
  text-align: center;
  font-size: 13px;
  color: black;
  min-height: 40px;
}

.btn {
  margin-top: auto;
  background-color: #5fbf77;
  color: #ffffff;
  border-radius: 13.5px;
  border: 1px;
  width: 107px;
  height: 37px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  background-repeat: no-repeat;
  background-position: 80%;
  cursor: pointer;
  transition: background-color 0.4s;
}

@media (max-width: 1000px) {
  .btn {
    margin-left: 13px;
    background-position: 75%;
    border-radius: 10.52px;
    width: 85.09px;
    height: 29.02px;
    font-size: 12px;
    line-height: 14.8px;
    background-size: 11.69px 11.1px;
  }
}

@media (max-width: 780px) {
  .btn {
    margin-left: 10px;
    background-position: 75%;
    border-radius: 9px;
    width: 73px;
    height: 25px;
    font-size: 10.27px;
    line-height: 12.67px;
    background-size: 10px 30px;
  }
}

@media (max-width: 660px) {
  .btn {
    display: none;
  }
}

.btn:hover {
  background-color: #fab325;
  color: black;
}
